import { merge } from 'lodash';
import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import { WARMUP_DATA_KEY } from 'store/constants';

import {
  selectIsLoggedIn,
  selectStateDeclarations,
} from 'store/application/selectors';

import { ApplicationController } from 'Group/controllers/application.controller';

import type { IAppData } from 'src/types';
import { FeedController } from './feed.controller';
import { GroupsController } from './groups.controller';
import { waitForTransitionToFinish } from 'Group/controllers/helpers';
import { IQueryJoinedGroupsResponse } from 'api/groups/types';

export const createController: CreateControllerFn = async (params) => {
  const { router, store } = params.appData as IAppData;
  const { flowAPI } = params;
  const { wixCodeApi, setProps } = flowAPI.controllerConfig;
  const { warmupData } = wixCodeApi.window;
  const { isSSR, isPreview, isEditor } = flowAPI.environment;
  const states = selectStateDeclarations(store.getState());

  const application = ApplicationController(params);
  const feed = FeedController(params);
  const groups = GroupsController(params);

  router.stateRegistry.deregister('groups');
  router.stateRegistry.register({
    ...states.groups,
    resolvePolicy: {
      async: isSSR ? 'WAIT' : 'NOWAIT',
    },
    resolve: {
      isLoggedIn: [() => selectIsLoggedIn(store.getState())],
      joinedGroups: [
        'isLoggedIn',
        (isLoggedIn: boolean) => {
          if (isLoggedIn) {
            return groups.groups$.queryJoinedGroups('').unwrap();
          }
        },
      ],
      centralFeed: [
        'joinedGroups',
        (response: IQueryJoinedGroupsResponse) => {
          if (response?.groups?.length) {
            return feed.feed$.fetch();
          }
        },
      ],
    },
  });

  if (isEditor || isPreview) {
    router.urlService.rules.initial({
      state: 'groups',
    });
  }

  return {
    async pageReady() {
      await Promise.all([
        application.$.handleUserLogin(),
        feed.$.initComments(),
      ]);

      await resolve();

      setInitialProps();

      router.urlService.listen();
      store.subscribe(updateStore);
    },
    updateConfig() {},
    onBeforeUnLoad() {
      feed.$.dispose();
      router.dispose();
    },
  };

  async function resolve() {
    router.urlService.sync();
    await waitForTransitionToFinish('groups', router);

    if (isSSR) {
      warmupData.set(WARMUP_DATA_KEY, store.getState());
    }
  }

  function setInitialProps() {
    feed.$.bindComments();
    setProps(
      merge(
        {
          fitToContentHeight: true,
          store: store.getState(),
        },
        application,
        feed,
        groups,
      ),
    );
  }

  function updateStore() {
    setProps({
      store: store.getState(),
    });
  }
};
