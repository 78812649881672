import type { ControllerParams } from '@wix/yoshi-flow-editor';
import type { CommentsControllerApi } from '@wix/comments-ooi-client/dist/types/controller/controller-public-types';
import type { DraftContent } from 'ricos-editor';

import {
  initializeCommentsController,
  PaginationState,
} from '@wix/comments-ooi-client/controller';

import * as feed from 'store/feed';
import * as topics from 'store/topics';

import { IAppData } from '../../../types';

export function FeedController(params: ControllerParams) {
  const { store } = params.appData as IAppData;
  let commentsApi: CommentsControllerApi | undefined;

  return {
    $: {
      async initComments() {
        commentsApi = await initializeCommentsController(
          params.controllerConfig,
          {
            shouldAutoBindStateToSetProps: false,
            appDefinitionId: params.controllerConfig.appParams.appDefinitionId,
            httpClient: params.flowAPI.httpClient,
          },
        );

        commentsApi.watch.pagination.onChange(handleCommentsChange);
      },
      bindComments() {
        commentsApi?.bindStateToSetProps();
      },
      dispose() {
        commentsApi?.unmountAllResources();
      },
    },
    feed$: {
      fetch,
      remove,
      react,
      unreact,
      subscribe,
      unsubscribe,
      update,
      create,
      updateDraft,
    },
    topics$: {
      create: createTopic,
      fetch: fetchTopics,
    },
  };

  function fetch(cursor?: string) {
    return store.dispatch(
      feed.thunks.fetchCentralFeed({
        commentsApi,
        cursor: {
          cursor,
          limit: 10,
        },
      }),
    );
  }

  function remove(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.remove({
        groupId,
        feedItemId,
      }),
    );
  }

  function react(groupId: string, feedItemId: string, code: string) {
    return store.dispatch(
      feed.thunks.react({
        code,
        groupId,
        feedItemId,
      }),
    );
  }

  function unreact(groupId: string, feedItemId: string, code: string) {
    return store.dispatch(
      feed.thunks.unreact({
        code,
        groupId,
        feedItemId,
      }),
    );
  }

  function subscribe(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.subscribe({
        feedItemId,
        groupId,
      }),
    );
  }

  function unsubscribe(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.unsubscribe({
        feedItemId,
        groupId,
      }),
    );
  }

  function update(
    groupId: string,
    feedItemId: string,
    content: string,
    topics: string[],
  ) {
    store.dispatch(
      feed.thunks.update({
        groupId,
        feedItemId,
        content,
        topics,
      }),
    );
  }

  function create(groupId: string, content: string, topics: string[]) {
    return store.dispatch(
      feed.thunks.create({
        groupId,
        content,
        topics,
        commentsApi,
      }),
    );
  }

  function handleCommentsChange(paginationState: PaginationState) {
    return store.dispatch(
      feed.actions.updateTotalComments(
        Object.fromEntries(
          Object.entries(paginationState).filter((data) => {
            const [, state] = data;

            return state.type === 'READY';
          }),
        ),
      ),
    );
  }

  function updateDraft(draftContent?: DraftContent) {
    return store.dispatch(feed.actions.updateDraft({ draftContent }));
  }

  function fetchTopics(groupId: string) {
    return store.dispatch(topics.thunks.fetch(groupId));
  }

  function createTopic(groupId: string, label: string) {
    return store.dispatch(
      topics.thunks.create({
        groupId,
        label,
      }),
    );
  }
}

export type IFeedController = Omit<ReturnType<typeof FeedController>, '$'>;
