import { createAsyncThunk } from '@reduxjs/toolkit';
import { FlowAPI } from '@wix/yoshi-flow-editor';

import * as api from 'api/topics/topics.api';

import * as application from '../application';

import { ICreateParams } from './types';

export const fetch = createAsyncThunk(
  'topics:fetch',
  async function (groupId: string, thunkAPI) {
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      const { data } = await httpClient.request(api.list(groupId));

      return data;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.topics.fetch'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const create = createAsyncThunk(
  'topics:create',
  async function (params: ICreateParams, thunkAPI) {
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      const { data } = await httpClient.request(
        api.create(params.groupId, params.label),
      );

      return data;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.topics.create', {
            name: params.label,
          }),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);
