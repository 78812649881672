import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContentType } from '@wix/ambassador-groups-feed-server/types';
import { FlowAPI } from '@wix/yoshi-flow-editor';

import type { CommentsControllerApi } from '@wix/comments-ooi-client/dist/types/controller/controller-public-types';

import * as api from 'api/feed';
import * as topics from '../topics';
import type { IFeedItem } from 'api/feed';
import { IMember, GroupRole } from 'api/members/types';
import type { IRootState } from '../types';

import * as application from '../application';
import { selectCurrentUser } from '../application/selectors';

import { selectors } from './adapter';
import { feedSlice } from './slice';

import {
  ISubscribeParams,
  IUnsubscribeParams,
  IPinParams,
  IUnpinParams,
  IReactParams,
  IUnreactParams,
  IFetchParams,
  ICreateParams,
  IRemoveParams,
  IUpdateParams,
  IGetParams,
  IFetchCentralFeedParams,
} from './types';

export const create = createAsyncThunk(
  'feed:create',
  async function (params: ICreateParams, thunkAPI) {
    const { groupId, commentsApi } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      const { data } = await httpClient.request(
        api.create(groupId, {
          entity: {
            topics: params.topics,
            body: {
              content: params.content,
              contentType: ContentType.DRAFTJS,
            },
          },
        }),
      );

      if (data.entity.topics.length) {
        thunkAPI.dispatch(
          topics.actions.incrementCounter(
            data.entity.topics.map((topic) => topic.id as string),
          ),
        );
      }

      await prefetchComments([data], commentsApi);

      return data;
    } catch (err) {
      const error = err as Error;
      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.feed.create'),
        }),
      );
      errorMonitor.captureException(error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const update = createAsyncThunk(
  'feed:update',
  async function (params: IUpdateParams, thunkAPI) {
    const { groupId, feedItemId } = params;
    const state = thunkAPI.getState() as IRootState;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    const old = selectors.selectById(state, feedItemId);

    try {
      const { data } = await httpClient.request(
        api.update(groupId, {
          feedItemId,
          entity: {
            topics: params.topics,
            body: {
              content: params.content,
              contentType: ContentType.DRAFTJS,
            },
          },
        }),
      );

      const removedTopics = old!.entity.topics.filter(
        (id) => !data.entity.topics.some((topic) => topic.id === id),
      );

      const addedTopics = data.entity.topics.filter(
        (topic) => !old?.entity.topics.some((id) => topic.id === id),
      );

      thunkAPI.dispatch(
        topics.actions.decrementCounter(
          removedTopics.map((topic) => topic.id as string),
        ),
      );

      thunkAPI.dispatch(
        topics.actions.incrementCounter(
          addedTopics.map((topic) => topic.id as string),
        ),
      );

      return data;
    } catch (err) {
      const error = err as Error;
      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.feed.update'),
        }),
      );
      errorMonitor.captureException(error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const subscribe = createAsyncThunk(
  'feed:subscribe',
  async function (params: ISubscribeParams, thunkAPI) {
    const { groupId, feedItemId } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      return await httpClient.request(api.subscribe(groupId, feedItemId));
    } catch (err) {
      const error = err as Error;
      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.feed.subscribe'),
        }),
      );
      errorMonitor.captureException(error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const unsubscribe = createAsyncThunk(
  'feed:unsubscribe',
  async function (params: IUnsubscribeParams, thunkAPI) {
    const { groupId, feedItemId } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      return await httpClient.request(api.unsubscribe(groupId, feedItemId));
    } catch (err) {
      const error = err as Error;
      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.feed.unsubscribe'),
        }),
      );
      errorMonitor.captureException(error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const pin = createAsyncThunk(
  'feed:pin',
  async function (params: IPinParams, thunkAPI) {
    const { groupId, feedItemId } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      return await httpClient.request(api.pin(groupId, feedItemId));
    } catch (err) {
      const error = err as Error;
      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.feed.pin'),
        }),
      );
      errorMonitor.captureException(error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const unpin = createAsyncThunk(
  'feed:unpin',
  async function (params: IUnpinParams, thunkAPI) {
    const { groupId, feedItemId } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      return await httpClient.request(api.unpin(groupId, feedItemId));
    } catch (err) {
      const error = err as Error;
      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.feed.unpin'),
        }),
      );
      errorMonitor.captureException(error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const react = createAsyncThunk(
  'feed:react',
  async function (params: IReactParams, thunkAPI) {
    const { dispatch, getState } = thunkAPI;
    const { groupId, code, feedItemId } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { t } = translations;

    const user = selectCurrentUser(getState() as IRootState) as IMember;

    try {
      dispatch(
        feedSlice.actions.react({
          feedItemId,
          code,
          user: {
            ...user,
            role: GroupRole.UNKNOWN_ROLE,
          },
        }),
      );

      return await httpClient.request(api.react(groupId, feedItemId, code));
    } catch (err) {
      const error = err as Error;
      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.feed.react', {
            code: params.code,
          }),
        }),
      );
      dispatch(
        feedSlice.actions.unreact({
          feedItemId,
          code,
          user: {
            ...user,
            role: GroupRole.UNKNOWN_ROLE,
          },
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const unreact = createAsyncThunk(
  'feed:unreact',
  async function (params: IUnreactParams, thunkAPI) {
    const { dispatch, getState } = thunkAPI;
    const { groupId, code, feedItemId } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { t } = translations;

    const user = selectCurrentUser(getState() as IRootState) as IMember;

    try {
      dispatch(
        feedSlice.actions.unreact({
          feedItemId,
          code,
          user: {
            ...user,
            role: GroupRole.UNKNOWN_ROLE,
          },
        }),
      );

      return await httpClient.request(api.unreact(groupId, feedItemId, code));
    } catch (err) {
      const error = err as Error;
      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.feed.unreact', {
            code: params.code,
          }),
        }),
      );
      dispatch(
        feedSlice.actions.react({
          feedItemId,
          code,
          user: {
            ...user,
            role: GroupRole.UNKNOWN_ROLE,
          },
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const remove = createAsyncThunk(
  'feed:remove',
  async function (params: IRemoveParams, thunkAPI) {
    const { groupId, feedItemId } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const state = thunkAPI.getState() as IRootState;
    const { t } = translations;

    const feedItem = selectors.selectById(state, feedItemId);

    if (feedItem?.entity.topics.length) {
      thunkAPI.dispatch(
        topics.actions.decrementCounter(
          feedItem?.entity.topics.map((topic) => topic.id as string),
        ),
      );
    }

    try {
      return await httpClient.request(api.remove(groupId, feedItemId));
    } catch (err) {
      const error = err as Error;
      thunkAPI.dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.feed.remove'),
        }),
      );
      errorMonitor.captureException(error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const get = createAsyncThunk(
  'feed:get',
  async function (params: IGetParams, thunkAPI) {
    const { groupId, feedItemId, commentsApi } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { t } = translations;

    try {
      const { data } = await httpClient.request(api.get(groupId, feedItemId));

      await prefetchComments([data.item], commentsApi);

      return data.item;
    } catch (err) {
      const error = err as Error;
      thunkAPI.dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.feed.get'),
        }),
      );
      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const fetch = createAsyncThunk(
  'feed:fetch',
  async function (params: IFetchParams, thunkAPI) {
    const { groupId, query, cursor, commentsApi } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { t } = translations;

    try {
      const response = await httpClient.request(
        api.fetch(groupId, {
          cursor,
          query,
        }),
      );

      await prefetchComments(response.data.items, commentsApi);

      return response;
    } catch (err) {
      const error = err as Error;
      thunkAPI.dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.feed.fetch'),
        }),
      );
      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const fetchCentralFeed = createAsyncThunk(
  'feed:central-feed:fetch',
  async function (params: IFetchCentralFeedParams, thunkAPI) {
    const { commentsApi } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { t } = translations;

    try {
      const response = await httpClient.request(
        api.centralFeed.fetch({
          limit: params.cursor?.limit!,
          cursor: params.cursor?.cursor,
        }),
      );

      await prefetchComments(response.data.items, commentsApi);

      return response;
    } catch (err) {
      const error = err as Error;
      thunkAPI.dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.feed.fetch'),
        }),
      );
      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

// TODO remove this thunk after FeedWidget migration to main Feed component
export const fetchNext = createAsyncThunk(
  'feed:fetch-more',
  function (params: IFetchParams, thunkAPI) {
    const { groupId, cursor, query } = params;
    const { httpClient } = thunkAPI.extra as FlowAPI;

    return httpClient.request(
      api.fetch(groupId, {
        cursor,
        query,
      }),
    );
  },
);

function prefetchComments(
  feedItems: IFeedItem[],
  commentsApi?: CommentsControllerApi,
) {
  return commentsApi?.bulkFetchComments(
    feedItems.map((feedItem) => ({
      resourceId: feedItem.feedItemId as string,
      ctxFields: {
        claims: ['wix.feed.FeedAccess'],
        contextType: 'postId',
        contextId: feedItem.applicationContext?.contextId as string,
      },
      pagination: {
        replyShowMoreLimit: 10,
        initialPage: {
          commentLimit: 1,
          replyLimit: 0,
          offset: 0,
        },
        pagination: {
          commentLimit: 20,
          replyLimit: 0,
        },
      },
    })),
  ) as unknown as Promise<void>;
}
