import type { RawParams, Transition, TransitionOptions } from '@uirouter/core';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

import * as application from 'store/application';
import type {
  IApplicationDialog,
  IShowDialogPayload,
  IShowToastPayload,
} from 'store/application/types';

import type { IAppData } from 'src/types';

export function ApplicationController(params: ControllerParams) {
  const { store, router } = params.appData as IAppData;
  const { wixCodeApi } = params.flowAPI.controllerConfig;

  router.transitionService.onBefore({}, handleRouterUpdate);

  wixCodeApi.user.onLogin(async () => {
    await handleUserLogin();
    router.stateService.reload();
  });

  return {
    $: {
      handleUserLogin,
    },
    application$: {
      shareProfileConsent,
      showToast,
      closeToast,
      showDialog,
      closeDialog,
      follow,
      unfollow,
      promptLogin,
    },
    router$: {
      go(state: string, params?: RawParams, options?: TransitionOptions) {
        router.stateService.go(state, params, options);
      },
      reload(state?: string) {
        router.stateService.reload(state);
      },
    },
  };

  function promptLogin() {
    store.dispatch(application.thunks.login());
  }

  function shareProfileConsent(groupId: string, value: boolean) {
    store.dispatch(application.actions.shareProfileConsent({ groupId, value }));
  }

  function follow(memberId: string) {
    store.dispatch(application.thunks.follow(memberId));
  }

  function unfollow(memberId: string) {
    store.dispatch(application.thunks.unfollow(memberId));
  }

  function showToast(params: IShowToastPayload) {
    store.dispatch(application.actions.showToast(params));
  }

  function closeToast(toastId: string) {
    store.dispatch(application.actions.closeToast(toastId));
  }

  function showDialog(params: IShowDialogPayload) {
    store.dispatch(application.actions.showDialog(params));
  }

  function closeDialog(dialog: IApplicationDialog) {
    store.dispatch(application.actions.closeDialog(dialog));
  }

  function handleUserLogin() {
    return store.dispatch(application.thunks.fetchCurrentUserProfile());
  }

  async function handleRouterUpdate(transition: Transition) {
    store.dispatch(
      application.actions.setRouterCurrent({
        url: router.urlService.url(),
        state: transition.to().name as string,
        params: transition.params('to'),
      }),
    );
  }
}

export type IApplicationController = Omit<
  ReturnType<typeof ApplicationController>,
  '$'
>;
