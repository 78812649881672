import { createSelector } from 'reselect';
import { filter, sortBy, curryRight } from 'lodash';

import { PrivacyStatus } from '@wix/ambassador-social-groups-v2-group/types';
import {
  MembershipStatus,
  Role,
} from '@wix/ambassador-social-groups-v2-group-member/types';
import { RequestStatus } from '@wix/ambassador-social-groups-v2-group-request/types';
import { NotificationChannel } from '@wix/ambassador-social-groups-v1-notification-settings/types';

import { selectIsSiteAdmin } from '../application/selectors';
import type { IRootState } from '../types';

import { MEMBER_LABELS } from './constants';
import { selectors } from './adapter';
import { GroupAppKey, GroupApp, IGroup } from './types';

type IGroupAppParams = {
  groupId: string;
  application: GroupAppKey;
};

export const selectGroupStatus = curryRight(
  createSelector(
    [(state: IRootState) => state.groups, (_, groupId: string) => groupId],
    (state, groupId) => ({
      fetch: state.fetch[groupId] || {},
      update: state.update[groupId] || {},
      remove: state.remove[groupId] || {},
      membership: state.membership[groupId] || {},
      activity: state.activity[groupId] || {},
      rules: state.rules[groupId] || {},
      requirements: state.requirements[groupId] || {},
      questions: state.questions[groupId] || {},
      applications: state.applications[groupId] || {},
      notificationSettingsStatus: state.notificationSettings[groupId] || {},
      permissions: {},
    }),
  ),
  2,
);

export const selectGroupFetchStatus = curryRight(
  createSelector(
    [(state: IRootState) => state.groups, (_, slug: string) => slug],
    (state, slug) => state.fetch[slug] || {},
  ),
  2,
);

export const selectIsGroupUpdating = curryRight(
  createSelector(selectGroupStatus, (status) => status.update.loading),
  2,
);

export const selectIsGroupLoading = curryRight(
  createSelector(selectGroupStatus, (status) => status.fetch.loading),
  2,
);

export const selectAreQuestionsLoading = curryRight(
  createSelector(selectGroupStatus, (status) => status.questions.loading),
  2,
);

export const selectAreQuestionsUpdating = curryRight(
  createSelector(selectGroupStatus, (status) => status.questions.updating),
  2,
);

export const selectAreApplicationsUpdating = curryRight(
  createSelector(selectGroupStatus, (status) => status.applications.updating),
  2,
);

export const selectGroup = curryRight(
  createSelector(
    [(state: IRootState) => state, (_, groupId: string) => groupId],
    (state: IRootState, groupId) =>
      (selectors.selectById(state, groupId) || {}) as IGroup,
  ),
  2,
);

export const selectGroupBySlug = curryRight(
  createSelector(
    [
      (state: IRootState) => selectors.selectAll(state),
      (_, slug: string) => slug,
    ],
    (groups, slug) =>
      groups.find((group) => group.slug === slug) || ({} as IGroup),
  ),
  2,
);

/**
 * Select groupId by slug
 */
export const selectGroupIdBySlug = curryRight(
  createSelector(selectGroupBySlug, (group) => group.id as string),
  2,
);

export const selectGroupUpdates = curryRight(
  createSelector(selectGroup, (group) => ({
    updates: group.updatesCount,
    members: group.pendingMembersCount,
  })),
  2,
);

export const selectGroupSlugById = curryRight(
  createSelector(selectGroup, (group) => group.slug as string),
  2,
);

export const selectGroupPrivacyStatus = curryRight(
  createSelector(selectGroup, (group) => group.privacyStatus),
  2,
);

export const selectIsGroupSecret = curryRight(
  createSelector(
    selectGroupPrivacyStatus,
    (privacyStatus) => privacyStatus === PrivacyStatus.SECRET,
  ),
  2,
);

export const selectIsGroupPending = curryRight(
  createSelector(
    selectGroup,
    (group) => group.status === RequestStatus.PENDING,
  ),
  2,
);

export const selectIsGroupPrivate = curryRight(
  createSelector(
    selectGroupPrivacyStatus,
    (privacyStatus) => privacyStatus === PrivacyStatus.PRIVATE,
  ),
  2,
);
export const selectIsGroupPublic = curryRight(
  createSelector(
    selectGroupPrivacyStatus,
    (privacyStatus) => privacyStatus === PrivacyStatus.PUBLIC,
  ),
  2,
);

export const selectGroupQuestions = curryRight(
  createSelector(selectGroup, (group) => group.questions || []),
  2,
);

export const selectAllApplications = curryRight(
  createSelector(selectGroup, (group) => {
    return sortBy(Object.values(group.applications || {}), 'tabOrderIndex');
  }),
  2,
);

export const selectInstalledApplications = curryRight(
  createSelector(selectAllApplications, (applications) => {
    return filter<GroupApp>(applications, 'installed');
  }),
  2,
);

export const selectApplication = curryRight(
  createSelector(
    [
      (state: IRootState, params: IGroupAppParams) =>
        selectGroup(state, params.groupId),
      (_, params: IGroupAppParams) => params.application,
    ],
    (group, application) => group.applications?.[application] as GroupApp,
  ),
  2,
);

export const selectHasAdminRole = curryRight(
  createSelector(selectGroup, (group) => group.role === Role.ADMIN),
  2,
);

export const selectHasMemberRole = curryRight(
  createSelector(selectGroup, (group) =>
    [Role.MEMBER, Role.ADMIN].includes(group.role),
  ),
  2,
);

export const selectIsPendingGroupMember = curryRight(
  createSelector(
    selectGroup,
    (group) => group.membership === MembershipStatus.PENDING,
  ),
  2,
);

export const selectIsJoinedGroupMember = curryRight(
  createSelector(
    selectGroup,
    (group) => group.membership === MembershipStatus.JOINED,
  ),
  2,
);

export const selectIsUnknownGroupMember = curryRight(
  createSelector(
    selectGroup,
    (group) => group.membership === MembershipStatus.UNKNOWN_STATUS,
  ),
  2,
);

export const selectGroupRestriction = curryRight(
  createSelector(selectGroup, (group) => group.accessRestriction?.type),
  2,
);

export const selectCanInviteMembers = curryRight(
  createSelector(selectGroup, (group) => {
    switch (group.role) {
      case Role.ADMIN:
        return true;

      case Role.MEMBER:
        return group.settings?.membersCanInvite;

      case Role.UNKNOWN_ROLE:
      default:
        return false;
    }
  }),
  2,
);

export const selectCanApproveMembers = curryRight(
  createSelector(selectGroup, (group) => {
    switch (group.role) {
      case Role.ADMIN:
        return true;

      case Role.MEMBER:
        return group.settings?.membersCanApprove;

      case Role.UNKNOWN_ROLE:
      default:
        return false;
    }
  }),
  2,
);

export const selectIsAppAvailable = curryRight(
  createSelector(
    [
      (state: IRootState, params: IGroupAppParams) =>
        selectGroup(state, params.groupId),
      (state: IRootState, params: IGroupAppParams) =>
        selectApplication(state, params),
      selectIsSiteAdmin,
    ],
    (group, application, isSiteAdmin) => {
      if (!group.id || !application) {
        return false;
      }
      const publicApplications = [
        GroupAppKey.ABOUT_APP,
        GroupAppKey.CUSTOM_APP,
      ];
      const isInstalled = application.installed!;
      const isJoined = group.membership === MembershipStatus.JOINED;

      if (publicApplications.includes(application.key as GroupAppKey)) {
        return isInstalled;
      }

      switch (group.privacyStatus) {
        case PrivacyStatus.PRIVATE:
        case PrivacyStatus.SECRET:
          return (isSiteAdmin || isJoined) && isInstalled;

        case PrivacyStatus.PUBLIC:
        default:
          return isInstalled;
      }
    },
  ),
  2,
);

export const selectMembersCount = curryRight(
  createSelector(selectGroup, (group) => group.membersCount),
  2,
);

export const selectMemberLabel = curryRight(
  createSelector(selectGroup, (group) => {
    const { memberTitle } = group;
    const key = memberTitle
      ? MEMBER_LABELS[memberTitle as keyof typeof MEMBER_LABELS]
      : null;

    const result = memberTitle ? key || memberTitle : MEMBER_LABELS.Members;

    return {
      label: result,
      isCustom: memberTitle ? !key : false,
    };
  }),
  2,
);

export const selectGroupRules = curryRight(
  createSelector(selectGroup, (group) => group.rules),
  2,
);

export const selectGroupActivity = curryRight(
  createSelector(selectGroup, (group) => group.activity),
  2,
);

export const selectGroupName = curryRight(
  createSelector(selectGroup, (group) => group.name),
  2,
);

export const selectGroupRequirements = curryRight(
  createSelector(selectGroup, (group) => group.requirements),
  2,
);

export const selectFuturePlans = curryRight(
  createSelector(selectGroupRequirements, (requirements) => {
    const { futurePlans } = requirements?.violation?.pricingPlansOptions || {};
    return futurePlans || [];
  }),
  2,
);

export const selectRequiredPricingPlanIds = curryRight(
  createSelector(selectGroupRequirements, (requirements) => {
    const { requiredPlans } =
      requirements?.violation?.pricingPlansOptions || {};
    if (requiredPlans) {
      return requiredPlans.map((plan) => plan.planId);
    }
    return [];
  }),
  2,
);

export const selectGroupSlug = curryRight(
  createSelector(selectGroup, (group) => group.slug),
  2,
);

export const selectEventsRestricted = curryRight(
  createSelector(selectGroupRequirements, (requirements) => {
    return requirements?.violation?.eventsOptions?.events || [];
  }),
  2,
);

export const selectCanSeeGroupActivity = curryRight(
  createSelector(
    selectIsJoinedGroupMember,
    selectIsGroupPublic,
    selectHasAdminRole,
    (isJoined, isPublic, isAdmin) => {
      return isJoined || isPublic || isAdmin;
    },
  ),
  2,
);

export const selectAllNotificationsSettings = curryRight(
  createSelector(selectGroup, (group) => group.notificationSettings || {}),
  2,
);

export const selectAreNotificationsSettingsUpdating = curryRight(
  createSelector(selectGroupStatus, (status) => {
    return Object.keys(status.notificationSettingsStatus).some((channel) => {
      return status.notificationSettingsStatus[channel as NotificationChannel]
        ?.updating;
    });
  }),
  2,
);

export const selectAreNotificationsSettingsLoading = curryRight(
  createSelector(selectGroupStatus, (status) => {
    return Object.keys(status.notificationSettingsStatus).some((channel) => {
      return status.notificationSettingsStatus[channel as NotificationChannel]
        ?.loading;
    });
  }),
  2,
);

export const selectGroupPermissions = curryRight(
  createSelector(selectGroup, (group) => group.permissions || {}),
  2,
);

export const selectFeedPermissions = curryRight(
  createSelector(
    selectGroupPermissions,
    (permissions) => permissions.feed || {},
  ),
  2,
);
