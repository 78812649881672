import { createAsyncThunk } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import type { FlowAPI, ViewerScriptFlowAPI } from '@wix/yoshi-flow-editor';

import {
  followMember,
  listMyMemberFollowing,
  unfollowMember,
} from '@wix/ambassador-members-v2-follow/http';
import * as members from 'api/members';

import type { IApplicationUser } from './types';

import * as application from '.';

export const follow = createAsyncThunk(
  'application:follow',
  async (memberId: string, thunkAPI) => {
    const flowApi = thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = flowApi.translations;

    try {
      await flowApi.httpClient.request(
        followMember({
          memberId,
        }),
      );

      return memberId;
    } catch (err) {
      const error = err as Error;
      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      dispatch(
        application.actions.showToast({
          type: 'error',
          message: t('groups-web.toast.error.application.follow'),
          description: error.message,
        }),
      );
      return Promise.reject(error);
    }
  },
);

export const unfollow = createAsyncThunk(
  'application:unfollow',
  async (memberId: string, thunkAPI) => {
    const flowApi = thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = flowApi.translations;

    try {
      await flowApi.httpClient.request(
        unfollowMember({
          memberId,
        }),
      );

      return memberId;
    } catch (err) {
      const error = err as Error;
      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.application.unfollow'),
        }),
      );
      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const login = createAsyncThunk(
  'application:login',
  async (_, thunkAPI) => {
    const { wixAPI } = thunkAPI.extra as ViewerScriptFlowAPI;

    try {
      await wixAPI.user.promptLogin({ modal: true });
      return thunkAPI.dispatch(fetchCurrentUserProfile());
    } catch (err) {
      return Promise.reject();
    }
  },
);

export const fetchCurrentUserProfile = createAsyncThunk(
  'application:fetchCurrentUserProfile',
  async function (_, thunkAPI) {
    const flowApi = thunkAPI.extra as ViewerScriptFlowAPI;
    const { t } = flowApi.translations;

    const wixUser = {
      ...flowApi.wixAPI.user.currentUser,
      instance: flowApi.wixAPI.site.getAppToken?.(
        flowApi.environment.appDefinitionId,
      ),
    };

    const user = pick(
      {
        ...flowApi.wixAPI.user.currentUser,
        instance: flowApi.wixAPI.site.getAppToken?.(
          flowApi.environment.appDefinitionId,
        ),
      },
      'loggedIn',
      'role',
      'instance',
    );

    if (!wixUser.loggedIn) {
      return {
        following: [],
        siteMemberId: wixUser.id,
        ...user,
      };
    }

    try {
      const response = await flowApi.httpClient.request(
        members.getMember(wixUser.id),
      );

      const following = await flowApi.httpClient
        .request(listMyMemberFollowing({}))
        .catch(() => ({
          data: { memberIds: [] },
        }));

      const member = response.data;

      if (!member) {
        throw new Error('Member not found');
      }

      return {
        ...user,
        ...member,
        following: following.data.memberIds || [],
      } as IApplicationUser;
    } catch (err) {
      const error = err as Error;
      thunkAPI.dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.application.login'),
        }),
      );
      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);
