import { createEntityAdapter } from '@reduxjs/toolkit';
import { IRootState } from 'store/index';

import { IGroup } from 'store/groups';

export const joinedGroupsAdapter = createEntityAdapter<IGroup>({
  selectId: (group) => group.id as string,
});

export const selectors = joinedGroupsAdapter.getSelectors<IRootState>(
  (state) => state.joinedGroups,
);
