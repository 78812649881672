import {
  Alignment,
  ButtonType,
  DEFAULT_GRID_CARD_SPACING,
  ImageRatio,
  LayoutType,
} from './settingsConstants';
import { GroupsRequestSort } from '@wix/social-groups-api';
import { ISettingsParam } from '@wix/tpa-settings';
import { transformSettingsKey } from '../../utils/utils';

export type ISettingsParams = {
  groupListTitle: ISettingsParam<string>;
  groupMembersLabel: ISettingsParam<string>;

  showHeaderTitle: ISettingsParam<boolean>;
  showSearchSorting: ISettingsParam<boolean>;
  showMemberCount: ISettingsParam<boolean>;
  showAdminBadge: ISettingsParam<boolean>;
  showImage: ISettingsParam<boolean>;
  showCreateGroupButton: ISettingsParam<boolean>; // use in sidebar layout
  showCreatePost: ISettingsParam<boolean>;
  showGroupsToJoin: ISettingsParam<boolean>;

  imageRatio: ISettingsParam<ImageRatio>;
  groupListLayout: ISettingsParam<LayoutType>;
  gridCardSpacing: ISettingsParam<number>;
  headerAlignment: ISettingsParam<Alignment>;
  sideBarAlignment: ISettingsParam<Alignment>;
  listCardSpacing: ISettingsParam<number>;

  buttonType: ISettingsParam<ButtonType>;

  sortBy: ISettingsParam<GroupsRequestSort>;
};

export const settingsParams: ISettingsParams = {
  /**
   * Text settings
   */
  groupListTitle: {
    /* null used for default value, because empty string is valid custom value */
    getDefaultValue: () => null as any,
    key: 'groupListTitle',
  },
  groupMembersLabel: {
    getDefaultValue: () => 'Members',
    key: 'groupMembersLabel',
  },

  /**
   * Display settings
   */
  showHeaderTitle: {
    getDefaultValue: () => true,
    key: 'showHeaderTitle',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  showSearchSorting: {
    getDefaultValue: () => true,
    key: 'showSearchSorting',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  showMemberCount: {
    getDefaultValue: () => true,
    key: 'showMemberCount',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  showAdminBadge: {
    getDefaultValue: () => true,
    key: 'showAdminBadge',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  showImage: {
    getDefaultValue: () => true,
    key: 'showImage',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  // use in sidebar layout
  showCreateGroupButton: {
    getDefaultValue: () => true,
    key: 'showCreateGroupButton',
  },
  showGroupsToJoin: {
    getDefaultValue: () => true,
    key: 'showGroupsToJoin',
  },
  showCreatePost: {
    getDefaultValue: () => true,
    key: 'showCreatePost',
  },

  /**
   * Layout settings
   */
  groupListLayout: {
    getDefaultValue: () => LayoutType.list,
    key: 'groupListLayout',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  headerAlignment: {
    getDefaultValue: () => Alignment.left,
    key: 'headerAlignment',
  },
  sideBarAlignment: {
    getDefaultValue: () => Alignment.right,
    key: 'sideBarAlignment',
  },
  imageRatio: {
    getDefaultValue: () => ImageRatio.rectangle,
    key: 'imageRatio',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  gridCardSpacing: {
    getDefaultValue: () => DEFAULT_GRID_CARD_SPACING,
    key: 'gridCardSpacing',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  listCardSpacing: {
    getDefaultValue: () => 0,
    key: 'listCardSpacing',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },

  /**
   * Design settings
   */
  buttonType: {
    getDefaultValue: () => ButtonType.rectangle,
    key: 'buttonType',
  },

  /**
   * Settings settings
   */
  sortBy: {
    getDefaultValue: () => GroupsRequestSort.RECENT_ACTIVITY,
    key: 'sortBy',
  },
};
