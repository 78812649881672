import type { UIRouter } from '@uirouter/core';

export async function waitForTransitionToFinish(
  state: string,
  router: UIRouter,
): Promise<void> {
  return new Promise<void>((resolve) => {
    const successListener = router.transitionService.onFinish(
      { to: state },
      handleResolve,
    );

    const errorListener = router.transitionService.onError({}, handleResolve);

    function handleResolve() {
      resolve();
      successListener();
      errorListener();
    }
  });
}
